import {Component, OnInit} from '@angular/core';
import {PlayerService} from '../player/player.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public isPlayer: boolean;
  public isFavoriteView: boolean;
  public isHistoryView: boolean;
  public isSearchView: boolean;
  public isDarkMode: boolean;
  public showDialog: boolean;

  constructor(private playerService: PlayerService) { }

  ngOnInit() {
    this.playerService.isPlayerValue.subscribe((isPlayer) => this.isPlayer = isPlayer);
    this.playerService.isFavoriteViewValue.subscribe((isFavoriteView) => this.isFavoriteView = isFavoriteView);
    this.playerService.isHistoryViewValue.subscribe((isHistoryView) => this.isHistoryView = isHistoryView);
    this.playerService.isSearchViewValue.subscribe((isSearchView) => this.isSearchView = isSearchView);
    this.playerService.isDarkMode.subscribe((isDarkMode) => this.isDarkMode = isDarkMode);
    this.showDialog = false;
  }

  public hideDialog(dialogShown: boolean) {
    this.showDialog = !dialogShown;
  }

}
