import { Component, OnInit } from '@angular/core';
import {ChannelGroupService} from '../channel-group.service';
import {ChannelGroup} from '../../models/ChannelGroup';
import {ChannelService} from '../channel.service';
import {Channel} from '../../models/Channel';
import {PlayerService} from '../../player/player.service';
import {HistoryService} from '../../history/history.service';
import {AuthService} from '../../auth/auth.service';
import {LanguageDE} from '../../models/languageDE';
import {LanguageService} from '../../language/language.service';
import {LanguageEN} from '../../models/languageEN';

@Component({
  selector: 'app-channel-group',
  templateUrl: './channel-group.component.html',
  styleUrls: ['./channel-group.component.scss']
})
export class ChannelGroupComponent implements OnInit {
  public isTrial: boolean;
  public daysLeft = 0;
  public channelGroups: ChannelGroup[];
  public languageFile = new LanguageDE();
  public isSafari: boolean;

  constructor(
    private languageService: LanguageService,
    private channelGroupService: ChannelGroupService,
    private channelService: ChannelService,
    private playerService: PlayerService,
    private historyService: HistoryService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en') {
        this.languageFile = new LanguageEN();
      }
    });
    this.authService.fetchUserInfo().subscribe((userInfo) => {
      if (userInfo.serviceStatus === 'TRIAL') {
        this.isTrial = true;
        this.daysLeft = userInfo.trialDays;
      } else if (userInfo.serviceStatus === 'ACTIVE') {
        this.isTrial = false;
      }
    });
    this.channelGroupService.channelGroupsValue.subscribe((channelGroups) => {
      if (channelGroups) {
        this.channelGroups = channelGroups;
      }
    });
    this.channelGroupService.showSafariMessageValue.subscribe((isSafari) => {
      this.isSafari = isSafari;
    });
  }

  public selectChannel(channel: Channel, channelGroup: ChannelGroup) {
    this.channelService.setCurrentChannelValue(channel);
    this.channelGroupService.setCurrentChannelGroup(channelGroup);
    this.playerService.setIsPlayerValue(true);
    this.historyService.setHistoryValue(channel.id);
  }

}
