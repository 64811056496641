export class LanguageEN {
  allCategories = 'All Categories';
  favorites = 'Favorites';
  history = 'History';
  website = 'Website';
  support = 'Support';
  dataProtection = 'Privacy';
  downloadApp = 'Download App:';
  dark = 'Dark';
  loggedInAs = 'Logged In As:';
  logout = 'Log Out';
  closeMenu = 'Close Menu';
  search = 'Search';
  trialVersion = 'Trial Version';
  days = 'days';
  day = 'day';
  moreInfo = 'More Information';
  register = 'Register';
  username = 'Username';
  password = 'Password';
  forgotPassword = 'Forgot Password';
  resetPassword = 'Reset password';
  cancel = 'Cancel';
  requestSubmitted = 'Your request was sent successfully.';
  checkEmail = 'Please check your inbox';
  emailNonExistent = "This Email address does not exist.";
  thereWasAnError = "There was an error.";
  tryAgain = "Please try again later";
  nowOnGooglePlay = 'Now on Google Play';
  downloadOnTheAppStore = 'Download on the App Store';
  playerImage = 'Webplayer Image';
  channelImage = 'Channel Image';
  openMenu = 'Open Menu';
  daysLeft = 'left';
  usernameRequired = 'The username must be entered';
  passwordRequired = 'The password must be entered';
  preventCrossSiteTrackingTitle = 'Prevent cross-site tracking';
  preventCrossSiteTrackingText = 'To use this website with Safari you have to deactivate \'prevent cross-site tracking\'';
  preventCrossSiteTrackingFirst = 'Safari -> Preferences';
  preventCrossSiteTrackingSecond = 'Go to \'Privacy\'';
  preventCrossSiteTrackingThird = 'Deactivate \'prevent cross-site tracking\'';
  preventCrossSiteTrackingFourth = 'Log out and log back in';
  tooltipFullView = "Full View";
  tooltipVolume = "Volume";
  tooltipPlay = "Play";
  tooltipPause = "Stop";
  tooltipSaveFavorite = "Save as favorite";
  tooltipRemoveFavorite = "Remove from favorites";
  tooltipPreviousChannel = "Previous channel";
  tooltipNextChannel = "Next channel";
  tooltipSwitchChannel = "Switch channel";
  wrongUsernameOrPassword = "Wrong Username or Password";
  dialogLine1 = 'hotelradio.fm becomes DMD2 Music';
  dialogLine2 = 'Dear customer';
  dialogLine3 = 'We are revising our product portfolio and therefore we are bundling all our music apps into the new "DMD2 Music" app. The design has been adapted to our new brand identity, but the functionalities as well as your access data remain the same.';
  dialogLine4 = 'We wish you a lot of fun with DMD2 Music!';
  dialogLine5 = 'With ❤️ from Bern 🇨🇭, the Team of DMD2';
  dialogLine6 = 'support@dmd2.com';
}
