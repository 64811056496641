import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {environment} from "../../environments/environment";
import {LanguageDE} from "../models/languageDE";
import {LanguageEN} from "../models/languageEN";
import {LanguageService} from "../language/language.service";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  public site = environment.site;
  public logoPath = environment.assetsPath + 'logo_main_big_light_background.svg';
  public backgroundImage = `url("${environment.assetsPath + 'background_main.png'}")`;
  public model = {
    username: ''
  };
  public success = true;
  public submitted = false;
  public message: string;
  public languageFile = new LanguageDE();

  constructor(private authService: AuthService, private languageService: LanguageService) { }

  ngOnInit() {
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en' || language === 'en-UK') {
        this.languageFile = new LanguageEN();
      }
    });
  }

  public resetPassword(): void {
    if (this.model.username) {
      this.submitted = true;
      this.authService.resetPassword(this.model.username).subscribe((response) => {
        console.log(response);
        if (response.status === 'success') {
          this.success = true;
        } else if (response.status === 'not_found') {
          this.success = false;
          this.message = 'not found';
        } else {
          this.success = true;
        }
      });
    }
  }

}
