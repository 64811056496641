import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LanguageDE} from '../../models/languageDE';
import {LanguageService} from "../../language/language.service";
import {LanguageEN} from "../../models/languageEN";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  public languageFile = new LanguageDE();
  public logoPath = environment.assetsPath + 'logo_main_big_light_background.svg';

  @Output() dialogShown = new EventEmitter<boolean>();

  private drawerContainer: HTMLElement;
  private body: HTMLElement;
  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en' || language === 'en-UK') {
        this.languageFile = new LanguageEN();
      }
    });
    this.logoPath = environment.assetsPath + 'logo_main_big_light_background.svg';
    this.disableClicks();
  }

  public dismiss() {
    console.log('dismiss');
    this.enableClicks();
    localStorage.setItem('dialogShown', '1');
    this.dialogShown.emit(true);
    // window.location.reload();
  }

  public disableClicks() {
    this.drawerContainer = document.querySelector('.drawer-container');
    this.body = document.querySelector('body');
    this.drawerContainer.style.pointerEvents = 'none';
    this.body.style.overflow = 'hidden';
  }

  public enableClicks() {
    this.drawerContainer = document.querySelector('.drawer-container');
    this.body = document.querySelector('body');
    this.drawerContainer.style.pointerEvents = 'all';
    this.body.style.overflow = 'auto';
  }
}
