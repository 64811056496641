import {Component, OnInit} from '@angular/core';
import {PlayerService} from '../player.service';
import {ChannelService} from '../../channel/channel.service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {ChannelGroupService} from '../../channel/channel-group.service';
import {LanguageService} from "../../language/language.service";
import {LanguageDE} from "../../models/languageDE";
import {LanguageEN} from "../../models/languageEN";
import {Channel} from "../../models/Channel";

@Component({
  selector: 'app-now-playing',
  templateUrl: './now-playing.component.html',
  styleUrls: ['./now-playing.component.scss']
})
export class NowPlayingComponent implements OnInit {
  private isPlayer: boolean;
  private sliderValue = 50;
  public nowPlaying = '';
  private headers = new Headers().set('Authorization', 'Bearer');
  private accessToken: string;
  public coverUrl: string;
  public channelName = '';
  public languageFile = new LanguageDE();
  public tooltipDelay = 600;
  private currentChannel: Channel;

  constructor(
    private playerService: PlayerService,
    private channelService: ChannelService,
    private http: HttpClient,
    private channelGroupService: ChannelGroupService,
    private languageService: LanguageService
  ) {
  }

  ngOnInit() {
    this.authenticateSpotify().subscribe((response) => {
      this.accessToken = response.access_token;
      this.channelService.nowPlaying.subscribe((nowPlay) => {
        if (nowPlay) {
          const header = new HttpHeaders().set('Authorization', 'Bearer ' + this.accessToken);
          
          if (nowPlay !== this.nowPlaying) {
            this.nowPlaying = nowPlay;
            const url = 'https://api.spotify.com/v1/search?type=track&limit=1&q=' + nowPlay.replace(' -', '').replace('(Explicit)', '');
            const artistTitle = nowPlay.split('-');
            if (artistTitle.length > 1) {
              this.http.get<any>(url, {headers: header})
                .subscribe((res) => {
                if (res.tracks.items[0] && res.tracks.items[0].album && res.tracks.items[0].album.images) {
                  this.channelService.setCoverUrl(res.tracks.items[0].album.images[0].url);
                } else {
                  this.channelService.setCoverUrl('');
                }
              }, error => {
                  // console.log(error.status);
                  if (error.status === 401) {
                    // this.getNewSpotifyToken();
                    console.log('spotify re auth');
                    this.authenticateSpotify().subscribe((res) => {
                      if (res) {
                        this.accessToken = res.access_token;
                        this.nowPlaying = '';
                        this.channelService.setNowPlaying(nowPlay);
                      }
                    });
                  }
                });
            } else {
              this.channelService.setCoverUrl('');
            }
          }
        }
      });
    });
    this.playerService.isPlayerValue.subscribe((isPlayer) => {
      this.isPlayer = isPlayer;
    });
    this.channelService.currentChannelValue.subscribe((channel) => {
      if (channel) {
        this.channelName = channel.stream_name;
      }
    });
    this.channelService.coverUrl.subscribe((url) => {
      if (url) {
        this.coverUrl = url;
      } else {
        this.channelService.currentChannelValue.subscribe((channel) => {
          if (channel) {
            if (channel.channel_cover) {
              this.channelService.setCoverUrl(channel.channel_cover);
            } else {
              this.channelService.setCoverUrl('../../../assets/ic_launcher-web.png');
            }
          }
        });
      }
    });
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en') {
        this.languageFile = new LanguageEN();
      }
    });
    this.channelService.currentChannelValue.subscribe(channel => this.currentChannel = channel);
  }

  private authenticateSpotify(): Observable<any> {
    const header = new HttpHeaders().set('Authorization', 'Ac496Ur097h45E83uEey59l0mO5648lv');
    return this.http.get('https://apps.swiss-dev.ch/hotelradio/auth.php', {headers: header}).pipe(
      take(1),
      map(response => response)
    );
  }
  
  private getNewSpotifyToken() {
    const header = new HttpHeaders().set('Authorization', 'Ac496Ur097h45E83uEey59l0mO5648lv');
  
    this.http.get<any>('https://apps.swiss-dev.ch/hotelradio/auth.php', {headers: header}).pipe(
      take(1),
      map(response => response)
    ).subscribe(res => {
      if (res.access_token) {
        this.accessToken = res.access_token;
        this.channelService.setCurrentChannelValue(this.currentChannel);
      }
    });
  }

  public switchToPlayerOrChannelOverview(): void {
    this.isPlayer = !this.isPlayer;
    this.playerService.setIsPlayerValue(this.isPlayer);
    this.playerService.setIsFavoriteViewValue(false);
    this.playerService.setIsHistoryViewValue(false);
    this.playerService.setSearchViewValue(false);
  }

}

export interface SpotifyRes {
  tracks: {
    items: []
  }
}
