import {HttpHeaders} from "@angular/common/http";

export class API {
  BASE_URL = 'https://localhost:4200/';
  BASE_API = 'http://localhost:4200/api/';
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'}),
    observe: 'response' as 'response',
    withCredentials: true
  };
}
