import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {LanguageService} from '../language/language.service';
import {LanguageDE} from '../models/languageDE';
import {LanguageEN} from '../models/languageEN';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  state$: Observable<object>;
  public site = environment.site;
  // public logoPath = environment.assetsPath + 'logo_main_big_light_background.png';
  public logoPath = environment.assetsPath + 'logo_main_big_light_background.svg';
  public backgroundImage = `url("${environment.assetsPath + 'background_main.png'}")`;
  public languageFile = new LanguageDE();
  public hasError = false;
  public wrongUsernameOrPassword = '';
  model = {
    username: '',
    password: '',
  };

  constructor(private authService: AuthService, private router: Router, private languageService: LanguageService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['wrongUsernameOrPassword']) {
        this.hasError = true;
      }
    });
    // this.backgroundImage = `url("../../${environment.assetsPath + 'background_main.png'}")`;
    this.logoPath = environment.assetsPath + 'logo_main_big_light_background.svg';
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en' || language === 'en-UK') {
        this.languageFile = new LanguageEN();
      }
      this.wrongUsernameOrPassword = this.languageFile.wrongUsernameOrPassword;
    });
  }

  login(): void {
    if (this.model.username && this.model.password) {
      this.authService.login2(this.model.username, this.model.password).pipe(
        first(),
      ).subscribe((res) => {
        this.router.navigate(['/home']);
      }, error => {
        if (error.status === 400) {
          this.router.navigate(['/login'], {queryParams: {'wrongUsernameOrPassword': true}});
        }
      });
    }
  }

  public changeLanguage(language: string) {
    this.languageService.changeLanguage(language);
  }
}
