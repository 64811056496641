import {Channel} from "./Channel";

export class ChannelGroup {
  id?: number;
  group_name?: string;
  demo?: boolean;
  prelisten?: boolean;
  free?: boolean;
  price?: string;
  created?: string;
  group_cover?: string;
  artwork?: string;
  channels?: Channel[];
}
