export class LanguageDE {
  allCategories = 'Alle Kategorien';
  favorites = 'Favoriten';
  history = 'Verlauf';
  website = 'Webseite';
  support = 'Support';
  dataProtection = 'Datenschutz';
  downloadApp = 'App herunterladen:';
  dark = 'Dunkel';
  loggedInAs = 'Eingeloggt als:';
  logout = 'Ausloggen';
  closeMenu = 'Menü schliessen';
  search = 'Suchen';
  trialVersion = 'Testversion';
  days = 'Tage';
  day = 'Tag';
  moreInfo = 'Weitere Informationen';
  register = 'Registrieren';
  username = 'Benutzername';
  password = 'Passwort';
  forgotPassword = 'Passwort vergessen';
  resetPassword = 'Passwort zurücksetzen';
  cancel = 'Abbrechen';
  requestSubmitted = 'Ihre Anfrage wurde erfolgreich übermittelt.';
  checkEmail = 'Bitte überprüfen Sie Ihren Mail Eingang';
  emailNonExistent = "Diese E-Mail Adresse existiert nicht.";
  thereWasAnError = "Es ist ein Fehler aufgetreten";
  tryAgain = "Versuchen Sie es später nochmal";
  nowOnGooglePlay = 'Jetzt auf Google Play';
  downloadOnTheAppStore = 'Laden im App Store';
  playerImage = 'Webplayer Bild';
  channelImage = 'Kanal Bild';
  openMenu = 'Menü öffnen';
  daysLeft = 'verbleibend';
  usernameRequired = 'Der Benutzername muss eingegeben werden';
  passwordRequired = 'Das Passwort muss eingegeben werden';
  preventCrossSiteTrackingTitle = 'Websiteübergreifendes Tracking verhindern';
  preventCrossSiteTrackingText = 'Um diese Webseite mit Safari zu verwenden, müssen Sie \'Websiteübergreifendes Tracking verhindern\' deaktivieren';
  preventCrossSiteTrackingFirst = 'Safari -> Einstellungen';
  preventCrossSiteTrackingSecond = 'Auf den Tab \'Datenschutz\' wechseln';
  preventCrossSiteTrackingThird = 'Häckchen bei \'Websiteübergreifendes Tracking verhindern\' entfernen';
  preventCrossSiteTrackingFourth = 'Ausloggen und wieder einloggen';
  tooltipFullView = "Grossansicht";
  tooltipVolume = "Lautstärke";
  tooltipPlay = "Wiedergabe starten";
  tooltipPause = "Wiedergabe stoppen";
  tooltipSaveFavorite = "Als Favorit speichern";
  tooltipRemoveFavorite = "Von Favoriten entfernen";
  tooltipPreviousChannel = "Vorheriger Kanal";
  tooltipNextChannel = "Nächster Kanal";
  tooltipSwitchChannel = "Kanalwechsel";
  wrongUsernameOrPassword = "Falscher Benutzername oder Passwort";
  dialogLine1 = 'Aus hotelradio.fm wird DMD2 Music';
  dialogLine2 = 'Lieber Kunde';
  dialogLine3 = 'Wir überarbeiten unser Produkteportfolio und bündeln daher alle unser Musik-Apps zur neuen "DMD2 Music" App. Das Design wurde unserem neuen Markenauftritt angepasst, die Funktionen jedoch bleiben dieselben, genauso wie auch Ihre Zugangsdaten';
  dialogLine4 = 'Wir wünschen weiterhin viel Spass mit DMD2 Music!';
  dialogLine5 = 'Mit ❤️ aus Bern 🇨🇭, das Team von DMD2';
  dialogLine6 = 'support@dmd2.com';

}
