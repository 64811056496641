import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Channel} from '../models/Channel';
import {ChannelService} from '../channel/channel.service';
import {ChannelGroupService} from '../channel/channel-group.service';
import {ChannelGroup} from '../models/ChannelGroup';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {
  @ViewChild('stream', {static: true}) stream: ElementRef;
  public isTrial: boolean;
  public currentChannel: Channel;
  public currentChannelGroup: ChannelGroup;
  public spotifyCover: string;
  public playerStyles = {};

  constructor(private channelService: ChannelService, private channelGroupService: ChannelGroupService) {
  }

  ngOnInit() {
    this.channelService.coverUrl.subscribe((url) => {
      if (url) {
        this.spotifyCover = url;
        this.playerStyles = {
          'background-image': 'url("' + url + '")'
        };
      }
    });
    this.channelService.currentChannelValue.subscribe((currentChannel) => {
      if (currentChannel) {
        this.currentChannel = currentChannel;
        if (!this.spotifyCover && currentChannel.coverart) {
          this.playerStyles = {
            'background-image': 'url("' + currentChannel.coverart + '")'
          };
        } else {
          this.channelGroupService.currentChannelGroupValue.subscribe((currentChannelGroup) => {
            if (currentChannelGroup) {
              this.currentChannelGroup = currentChannelGroup;
              if (!this.spotifyCover && currentChannelGroup.group_cover) {
                this.playerStyles = {
                  'background-image': 'url("' + currentChannelGroup.group_cover + '")'
                };
              }
            }
          });
        }
      }
    });
  }

}
