import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  private isPlayerSubject: BehaviorSubject<boolean>;
  private isPlayer: Observable<boolean>;
  private isFavoriteViewSubject: BehaviorSubject<boolean>;
  private isFavoriteView: Observable<boolean>;
  private isHistoryViewSubject: BehaviorSubject<boolean>;
  private isHistoryView: Observable<boolean>;
  private isSearchViewSubject: BehaviorSubject<boolean>;
  private isSearchView: Observable<boolean>;
  private isDarkModeSubject: BehaviorSubject<boolean>;
  private isDarkModeValue: Observable<boolean>;
  private isMonoModeSubject: BehaviorSubject<boolean>;
  private isMonoModeValue: Observable<boolean>;

  constructor() {
    this.isPlayerSubject = new BehaviorSubject<boolean>(false);
    this.isPlayer = this.isPlayerSubject.asObservable();
    this.isFavoriteViewSubject = new BehaviorSubject<boolean>(false);
    this.isFavoriteView = this.isFavoriteViewSubject.asObservable();
    this.isHistoryViewSubject = new BehaviorSubject<boolean>(false);
    this.isHistoryView = this.isHistoryViewSubject.asObservable();
    this.isSearchViewSubject = new BehaviorSubject<boolean>(false);
    this.isSearchView = this.isSearchViewSubject.asObservable();
    this.isDarkModeSubject = new BehaviorSubject<boolean>(false);
    this.isDarkModeValue = this.isDarkModeSubject.asObservable();

    if (localStorage.getItem('monoMode')) {
      const monoModeString = localStorage.getItem('monoMode');
      const monoMode = monoModeString === 'true';
      this.isMonoModeSubject = new BehaviorSubject<boolean>(monoMode);
      this.isMonoModeValue = this.isMonoModeSubject.asObservable();
    } else {
      localStorage.setItem('monoMode', 'false');
      this.isMonoModeSubject = new BehaviorSubject<boolean>(false);
      this.isMonoModeValue = this.isMonoModeSubject.asObservable();
    }
    
    if (localStorage.getItem('darkMode')) {
      const darkModeString = localStorage.getItem('darkMode');
      const darkMode = darkModeString === 'true';
      this.isDarkModeSubject = new BehaviorSubject<boolean>(darkMode);
      this.isDarkModeValue = this.isDarkModeSubject.asObservable();
    } else {
      if (this.isDarkMode) {
        localStorage.setItem('darkMode', 'true');
        this.isDarkModeSubject = new BehaviorSubject<boolean>(true);
        this.isDarkModeValue = this.isDarkModeSubject.asObservable();
      } else {
        localStorage.setItem('darkMode', 'false');
        this.isDarkModeSubject = new BehaviorSubject<boolean>(false);
        this.isDarkModeValue = this.isDarkModeSubject.asObservable();
      }
    }
  }

  public get isPlayerValue(): Observable<boolean> {
    return this.isPlayer;
  }

  public setIsPlayerValue(isPlayer: boolean) {
    this.isPlayerSubject.next(isPlayer);
  }

  public get isFavoriteViewValue(): Observable<boolean> {
    return this.isFavoriteView;
  }

  public setIsFavoriteViewValue(isFavoriteView: boolean) {
    this.isFavoriteViewSubject.next(isFavoriteView);
  }

  public get isHistoryViewValue(): Observable<boolean> {
    return this.isHistoryView;
  }

  public setIsHistoryViewValue(isHistoryView: boolean) {
    this.isHistoryViewSubject.next(isHistoryView);
  }

  public get isSearchViewValue(): Observable<boolean> {
    return this.isSearchView;
  }

  public setSearchViewValue(isSearchView: boolean): void {
    this.isSearchViewSubject.next(isSearchView);
  }

  public get isDarkMode(): Observable<boolean> {
    return this.isDarkModeValue;
  }

  public setIsDarkMode(isDarkMode: boolean): void {
    localStorage.setItem('darkMode', isDarkMode.toString());
    this.isDarkModeSubject.next(isDarkMode);
  }

  public get isMonoMode(): Observable<boolean> {
    return this.isMonoModeValue;
  }

  public setIsMonoMode(isMonoMode: boolean): void {
    localStorage.setItem('monoMode', isMonoMode.toString());
    this.isMonoModeSubject.next(isMonoMode);
  }
  
  private prefersDarkmode(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
}
