export class User {
  id: number;
  username: string;
  ip?: string;
  mac_address?: string;
  company?: string;
  firstname?: string;
  lastname?: string;
  address?: string;
  zip?: number;
  place?: string;
  state?: string;
  country?: string;
  country_iso?: string;
  phone?: string;
  fax?: string;
  web?: string;
  email?: string;
  slots?: number;
  ftphost?: number;
  lang?: string;
  regdate?: string;
  lasttouch?: string;
  lastauth?: string;
  active?: boolean;
  issupport?: boolean;
  isadmin?: boolean;
  superuser?: boolean;
  demouser?: boolean;
  mailsent?: boolean;
  co_brand?: string;
  partnerlink?: string;
  keepFtpPath?: boolean;
  brand?: string;
  moodimage1?: string;
  moodimage2?: string;
  moodimage3?: string;
  verified?: boolean;
  notepad?: string;
  customer_billing_method?: string;
  isLegacy?: boolean;
  acceptedGTC?: string;
  acceptedPrivacy?: string;
}
