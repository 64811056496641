import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthComponent } from './auth/auth.component';
import {AuthService} from './auth/auth.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {AuthInterceptor} from './auth/auth.interceptor';
import { SidebarComponent } from './home/sidebar/sidebar.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { PlayerComponent } from './player/player.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ChannelGroupComponent } from './channel/channel-group/channel-group.component';
import { PlayerMiniComponent } from './player/player-mini/player-mini.component';
import { NowPlayingComponent } from './player/now-playing/now-playing.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatIconModule} from '@angular/material/icon';
import { ChannelPreviewComponent } from './player/channel-preview/channel-preview.component';
import { FavoritesComponent } from './favorites/favorites/favorites.component';
import { HistoryComponent } from './history/history.component';
import { SearchComponent } from './search/search.component';
import {MatCardModule} from '@angular/material/card';
import { environment } from '../environments/environment';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { DialogComponent } from './home/dialog/dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    AuthComponent,
    SidebarComponent,
    PlayerComponent,
    ChannelGroupComponent,
    PlayerMiniComponent,
    NowPlayingComponent,
    ChannelPreviewComponent,
    FavoritesComponent,
    HistoryComponent,
    SearchComponent,
    PasswordResetComponent,
    DialogComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
