import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  private favoritesSubject: BehaviorSubject<any>;
  private favorites: Observable<any>;

  constructor(private http: HttpClient, private authService: AuthService) {
    if (localStorage.getItem('favorites')) {
      this.favoritesSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('favorites')));
      this.favorites = this.favoritesSubject.asObservable();
    } else {
      const favoritesArr = [];
      localStorage.setItem('favorites', JSON.stringify(favoritesArr));
      this.favoritesSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('favorites')));
      this.favorites = this.favoritesSubject.asObservable();
    }
  }

  public get favoritesValue(): Observable<any> {
    return this.favorites;
  }
  
  public setFavorite(channelId: number) {
    const favoritesArr = JSON.parse(localStorage.getItem('favorites'));
    if (!favoritesArr.includes(channelId)) {
      favoritesArr.push(channelId);
      localStorage.setItem('favorites', JSON.stringify(favoritesArr));
      this.favoritesSubject.next(favoritesArr);
    }
  }
  
  public removeFavorite(channelId: number) {
    const favoritesArr = JSON.parse(localStorage.getItem('favorites'));
    const index = favoritesArr.indexOf(channelId);
    if (index >= 0) {
      favoritesArr.splice(index, 1);
    }
    localStorage.setItem('favorites', JSON.stringify(favoritesArr));
    this.favoritesSubject.next(favoritesArr);
  }
}
