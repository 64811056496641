import { Component, OnInit } from '@angular/core';
import {ChannelService} from '../../channel/channel.service';
import {ChannelGroupService} from '../../channel/channel-group.service';
import {Channel} from '../../models/Channel';
import {ChannelGroup} from '../../models/ChannelGroup';
import {HistoryService} from '../../history/history.service';
import {combineLatest} from 'rxjs';
import {LanguageDE} from "../../models/languageDE";
import {LanguageService} from "../../language/language.service";
import {LanguageEN} from "../../models/languageEN";

@Component({
  selector: 'app-channel-preview',
  templateUrl: './channel-preview.component.html',
  styleUrls: ['./channel-preview.component.scss']
})
export class ChannelPreviewComponent implements OnInit {
  public currentChannel: Channel;
  public nextChannel: Channel;
  public previousChannel: Channel;
  public currentChannelGroup: ChannelGroup;
  public readyToPlay: boolean;
  public languageFile = new LanguageDE();
  public tooltipDelay = 600;

  constructor(
    private channelService: ChannelService,
    private channelGroupService: ChannelGroupService,
    private historyService: HistoryService,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    const currentChannelGroupObservable = this.channelGroupService.currentChannelGroupValue;
    const currentChannelObservable = this.channelService.currentChannelValue;
    combineLatest(currentChannelGroupObservable, currentChannelObservable).subscribe(([channelGroup, channel]) => {
      if (channelGroup && channel) {
        this.currentChannelGroup = channelGroup;
        this.currentChannel = channel;
        this.nextChannel = this.getNextChannel(channelGroup, channel);
        this.previousChannel = this.getPreviousChannel(channelGroup, channel);
      }
    });
    /*this.channelGroupService.currentChannelGroupValue.subscribe((currentChannelGroup) => {
      if (currentChannelGroup) {
        this.currentChannelGroup = currentChannelGroup;
        this.channelService.currentChannelValue.subscribe((channel) => {
          if (channel) {
            this.currentChannel = channel;
            this.nextChannel = this.getNextChannel(currentChannelGroup, channel);
            this.previousChannel = this.getPreviousChannel(currentChannelGroup, channel);
          }
        });
      }
    });*/
    this.channelService.readyToPlay.subscribe((readyToPlay) => this.readyToPlay = readyToPlay);
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en') {
        this.languageFile = new LanguageEN();
      }
    });
  }

  private getNextChannel(currentChannelGroup: ChannelGroup, channel: Channel): Channel {
    let nextChannelPosition = 0;
    const channelPosition = this.getChannelIndex(currentChannelGroup, channel);
    if (channelPosition < (this.currentChannelGroup.channels.length - 1)) {
      nextChannelPosition = channelPosition + 1;
    } else if ((channelPosition + 1) === this.currentChannelGroup.channels.length) {
      nextChannelPosition = 0;
    }
    return this.currentChannelGroup.channels[nextChannelPosition];
  }

  private getPreviousChannel(currentChannelGroup: ChannelGroup, channel: Channel): Channel {
    let previousChannelPosition = 0;
    const channelPosition = this.getChannelIndex(currentChannelGroup, channel);
    if (channelPosition > 0) {
      previousChannelPosition = channelPosition - 1;
    } else if (channelPosition === 0) {
      previousChannelPosition = this.currentChannelGroup.channels.length - 1;
    }
    return this.currentChannelGroup.channels[previousChannelPosition];
  }

  public changeNextChannel(): void {
    this.previousChannel = this.currentChannel;
    this.currentChannel = this.nextChannel;
    this.nextChannel = this.getNextChannel(this.currentChannelGroup, this.currentChannel);
  }

  public changePreviousChannel(): void {
    this.nextChannel = this.currentChannel;
    this.currentChannel = this.previousChannel;
    this.previousChannel = this.getPreviousChannel(this.currentChannelGroup, this.currentChannel);
  }

  public changeChannel(): void {
    if (this.readyToPlay) {
      this.channelService.setCurrentChannelValue(this.currentChannel);
      this.historyService.setHistoryValue(this.currentChannel.id);
    }
  }

  private getChannelIndex(currentChannelGroup: ChannelGroup, currentChannel: Channel): number {
    return currentChannelGroup.channels.map((channel) => channel.id).indexOf(currentChannel.id);
  }
}
