import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private historyChangedSubject: BehaviorSubject<boolean>;
  private readonly historyChangedValue: Observable<boolean>;

  constructor() {
    if (!localStorage.getItem('hotelradioChannelHistory')) {
      localStorage.setItem('hotelradioChannelHistory', JSON.stringify([]));
    }
    this.historyChangedSubject = new BehaviorSubject<any>(true);
    this.historyChangedValue = this.historyChangedSubject.asObservable();
  }

  public get historyChanged(): Observable<boolean> {
    return this.historyChangedValue;
  }

  public setHistoryValue(channelId: number): void {
    const historyArr = Object.values(JSON.parse(localStorage.getItem('hotelradioChannelHistory')));
    if (!historyArr.includes(channelId)) {
      historyArr.unshift(channelId);
    } else {
      historyArr.splice(historyArr.indexOf(channelId), 1);
      historyArr.unshift(channelId);
    }
    if (historyArr.length > 20) {
      historyArr.shift();
    }
    localStorage.setItem('hotelradioChannelHistory', JSON.stringify(historyArr));
    this.historyChangedSubject.next(true);
  }
}
