export const environment = {
  production: true,
  site: 'hotelradio',
  // apiUrl: 'https://users.hotelradio.fm/api/',
  apiUrl: 'https://your.dmd2.com/api/',
  baseUrl: 'https://your.dmd2.com/',
  assetsPath: 'assets/hotelradio/',
  googleLink: 'https://play.google.com/store/apps/details?id=hotelradio.futuretek.ch.hotelradiofm&hl=de_DE&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  appleLink: 'https://apps.apple.com/us/app/hotelradio-fm/id714281345?mt=8',
  title: 'DMD2 Music',
  nowPlayingUrl: 'https://play.dmd2.com/stream/nowplaying/index.php',
  websiteLink: 'https://dmd2.com'
};
