import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public searchTextSubject = new Subject<string>();
  private searchTextValue: Observable<string>;
  private delay = 500;

  constructor() {
    this.searchTextValue = this.searchTextSubject.pipe(
      debounceTime(this.delay),
      distinctUntilChanged(),
      map(searchText => searchText)
    );
  }

  public setSearchText(searchText: string): void {
    this.searchTextSubject.next(searchText);
  }

  public get searchText(): Observable<string> {
    return this.searchTextValue;
  }
}
