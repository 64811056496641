import { Component, OnInit } from '@angular/core';
import {SearchService} from './search.service';
import {ChannelGroup} from '../models/ChannelGroup';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {ChannelGroupService} from '../channel/channel-group.service';
import {Channel} from '../models/Channel';
import {ChannelService} from '../channel/channel.service';
import {PlayerService} from '../player/player.service';
import {HistoryService} from '../history/history.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  private currentChannelGroups: ChannelGroup[];
  public channelGroup: ChannelGroup;
  public channels: Channel[] = [];
  private searchText = new Subject<string>();
  private searchResult: any;

  constructor(private searchService: SearchService, private channelGroupService: ChannelGroupService, private channelService: ChannelService, private playerService: PlayerService, private historyService: HistoryService) { }

  ngOnInit() {
    this.channelGroupService.channelGroupsValue.subscribe((channelGroups) => {
      if (channelGroups) {
        this.currentChannelGroups = channelGroups;
        channelGroups.forEach(channelGroup => {
          if (channelGroup.channels) {
            channelGroup.channels.forEach(channel => {
              if (channel) {
                this.channels.push(channel);
              }
            });
          }
        });
      }
    });
    this.channelGroup = {};
    this.channelGroup.channels = [];
    this.channelGroup.group_name = 'Suche';
    this.channelGroup.id = 0;
    this.searchService.searchText.subscribe((searchText) => {
      if (searchText) {
        this.setSearchChannels(searchText);
      }
    });
  }

  private setSearchChannels(channelName): void {
    const tempChannelGroup: ChannelGroup = {};
    tempChannelGroup.channels = [];
    const channelNameArr = [];
    this.currentChannelGroups.forEach((channelGroup) => {
      if (channelGroup.channels) {
        channelGroup.channels.filter((channel) => {
          return channel.stream_name.toLowerCase().includes(channelName.toLowerCase());
        }).filter((channel) => {
          return !channelNameArr.includes(channel.stream_name);
        }).map((channel) => {
          tempChannelGroup.channels.push(channel);
          channelNameArr.push(channel.stream_name);
        });
      }
    });
    this.channelGroup.channels = tempChannelGroup.channels;
    this.channels = tempChannelGroup.channels;
  }

  public selectChannel(channel: Channel, channelGroup: ChannelGroup): void {
    this.channelService.setCurrentChannelValue(channel);
    this.channelGroupService.setCurrentChannelGroup(channelGroup);
    this.playerService.setIsFavoriteViewValue(false);
    this.playerService.setSearchViewValue(false);
    this.playerService.setIsHistoryViewValue(false);
    this.playerService.setIsPlayerValue(true);
    this.historyService.setHistoryValue(channel.id);
  }
}
