import { Component, OnInit } from '@angular/core';
import {Channel} from '../models/Channel';
import {ChannelGroup} from '../models/ChannelGroup';
import {ChannelGroupService} from '../channel/channel-group.service';
import {ChannelService} from '../channel/channel.service';
import {PlayerService} from '../player/player.service';
import {HistoryService} from './history.service';
import {combineLatest} from 'rxjs';
import {LanguageDE} from '../models/languageDE';
import {LanguageService} from '../language/language.service';
import {LanguageEN} from '../models/languageEN';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  public channels: Channel[] = [];
  public channelGroup: ChannelGroup = new ChannelGroup();
  public languageFile = new LanguageDE();

  constructor(
    private languageService: LanguageService,
    private channelGroupService: ChannelGroupService,
    private historyService: HistoryService,
    private channelService: ChannelService,
    private playerService: PlayerService
  ) { }

  ngOnInit() {
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en') {
        this.languageFile = new LanguageEN();
      }
    });
    this.channelGroup.channels = [];
    this.channelGroup.group_name = 'Verlauf';
    this.channelGroup.id = 0;
    const historyObservable = this.historyService.historyChanged;
    const channelGroupsObservable = this.channelGroupService.channelGroupsValue;
    combineLatest(historyObservable, channelGroupsObservable).subscribe(([historyChanged, channelGroups]) => {
      if (historyChanged) {
        const pushedChannels = [];
        const historyArr = Object.values(JSON.parse(localStorage.getItem('hotelradioChannelHistory')));
        historyArr.forEach(historyItem => {
          channelGroups.forEach((channelGroup) => {
            if (channelGroup.channels) {
              channelGroup.channels.forEach((channel) => {
                if (!channel.coverart && channelGroup.group_cover) {
                  channel.coverart = channelGroup.group_cover;
                }
                if (historyItem === channel.id && !pushedChannels.includes(channel.id)) {
                  pushedChannels.push(channel.id);
                  this.channelGroup.channels.push(channel);
                  this.channels.push(channel);
                }
              });
            }
          });
        });
      }
    });
  }

  public selectChannel(channel: Channel, channelGroup: ChannelGroup) {
    this.channelService.setCurrentChannelValue(channel);
    this.channelGroupService.setCurrentChannelGroup(channelGroup);
    this.playerService.setIsFavoriteViewValue(false);
    this.playerService.setSearchViewValue(false);
    this.playerService.setIsHistoryViewValue(false);
    this.playerService.setIsPlayerValue(true);
    this.historyService.setHistoryValue(channel.id);
  }
}
