import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChannelService} from '../../channel/channel.service';
import {Channel} from '../../models/Channel';
import {ChannelGroup} from '../../models/ChannelGroup';
import {ChannelGroupService} from '../../channel/channel-group.service';
import {combineLatest} from 'rxjs';
import {FavoritesService} from '../../favorites/favorites.service';
import {PlayerService} from '../player.service';
import {MatSlider} from '@angular/material/slider';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {LanguageService} from '../../language/language.service';
import {LanguageDE} from '../../models/languageDE';
import {LanguageEN} from '../../models/languageEN';

@Component({
  selector: 'app-player-mini',
  templateUrl: './player-mini.component.html',
  styleUrls: ['./player-mini.component.scss']
})
export class PlayerMiniComponent implements OnInit, OnDestroy {
  @ViewChild('volumeSlider', {static: false}) volumeSlider: MatSlider;
  @ViewChild('durationSlider', {static: false}) durationSlider: MatSlider;
  public sliderValue = 50;
  protected audio = new Audio();
  public currentChannel: Channel;
  public currentChannelGroup: ChannelGroup;
  public isPlay = false;
  public isFavorite = false;
  public favorites = [];
  public showVolumeSlider = false;
  private timeupdate = 0;
  private nowPlaying = '';
  private coverReset = false;
  public readyToPlay: boolean;
  public isDarkMode: boolean;
  private isMonoMode: boolean;
  private nowPlayingUrl = environment.nowPlayingUrl;
  public languageFile = new LanguageDE();
  public tooltipDelay = 600;
  public showDurationSlider = false;
  public currentTime = this.getHHMMSS(0);

  constructor(
    private channelService: ChannelService,
    private channelGroupService: ChannelGroupService,
    private favoritesService: FavoritesService,
    private playerService: PlayerService,
    private http: HttpClient,
    private languageService: LanguageService
  ) {
  }

  ngOnInit() {
    this.audio = new Audio();
    const currentChannelObservable = this.channelService.currentChannelValue;
    const monoModeObservable = this.playerService.isMonoMode;
    combineLatest(currentChannelObservable, monoModeObservable)
      .subscribe(([currentChannel, isMonoMode]) => {
        if (currentChannel) {
          this.timeupdate = 0;
          this.currentChannel = currentChannel;
          this.coverReset = true;
          this.isMonoMode = isMonoMode;
          this.createAudioElement(
            isMonoMode,
            currentChannel.stream_path,
            currentChannel.stream_dir,
            currentChannel.aacp_stream_dir,
            currentChannel.mp3128_stream_dir
          );
          const favoritesArr = JSON.parse(localStorage.getItem('favorites'));
          this.isFavorite = favoritesArr.includes(currentChannel.id);
        }
      });
    this.channelGroupService.currentChannelGroupValue.subscribe((currentChannelGroup) => {
      if (currentChannelGroup) {
        this.currentChannelGroup = currentChannelGroup;
      }
    });
    this.playerService.isDarkMode.subscribe(darkMode => this.isDarkMode = darkMode);
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en') {
        this.languageFile = new LanguageEN();
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.audio = null;
  }

  private createAudioElement(isMonoMode: boolean, streamPath, streamDir, aacpStreamDir, mp3128StreamDir): void {
    // this.channelService.setReadyToPlay(false);

    if (isMonoMode) {
      this.audio.src = streamPath + streamDir;
    } else {
      if (mp3128StreamDir) {
        this.audio.src = streamPath + mp3128StreamDir;
      } else if (aacpStreamDir) {
        this.audio.src = streamPath + aacpStreamDir;
      } else {
        this.audio.src = streamPath + streamDir;
      }
    }
    this.audio.load();
    this.audio.play().then(() => console.log('playing'));

    if (streamPath.includes('static.')) {
      this.showDurationSlider = true;
      this.audio.ontimeupdate = () => {
          this.currentTime = this.getHHMMSS(this.audio.currentTime)
      };
      this.whatsPlayingStatic();
    } else {
      this.audio.ontimeupdate = () => {
        if (this.audio.duration === Infinity) {
          if (this.timeupdate === 0 || this.timeupdate === 16) {
            this.whatsPlaying();
            this.timeupdate = 0;
          }
          this.timeupdate++;
        }
      };
      this.showDurationSlider = false;
    }
    this.isPlay = true;
  }

  private isChrome(): boolean {
    return !!window['chrome'] && (!!window['chrome'].webstore || !!window['chrome'].runtime);
  }

  public pause(): void {
    if (this.showDurationSlider) {
      this.audio.pause();
    } else {
      this.audio.src = "";
      this.audio.load();
    }

    this.isPlay = false;
  }

  public play(): void {
    if (this.showDurationSlider && this.audio.src.includes('static.')) {
      this.audio.play().then(() => console.log('resume'));
      this.isPlay = true;
    } else {
      this.createAudioElement(
        this.isMonoMode,
        this.currentChannel.stream_path,
        this.currentChannel.stream_dir,
        this.currentChannel.aacp_stream_dir,
        this.currentChannel.mp3128_stream_dir
      );
    }
  }

  private whatsPlaying(): void {
    const streamUrl = this.currentChannel.stream_path + this.currentChannel.stream_dir;
    const body = new HttpParams().set('streamurl', streamUrl);
    const url = this.nowPlayingUrl;
    this.http.get<any>(url, {params: body}).subscribe((res) => {
      const title = res.title;
      if (this.nowPlaying !== title || this.coverReset) {
        this.channelService.setNowPlaying(title);
        this.nowPlaying = title;
      }
    });
  }

  private whatsPlayingStatic(): void {
    const title = this.currentChannel.stream_name;

    this.channelService.setNowPlaying(title);
    this.nowPlaying = title;
  }

  public nextChannel(): void {
    let nextChannelPosition = 0;
    const channelPosition = this.getChannelIndex();
    if (channelPosition < (this.currentChannelGroup.channels.length - 1)) {
      nextChannelPosition = channelPosition + 1;
    } else if ((channelPosition + 1) === this.currentChannelGroup.channels.length) {
      nextChannelPosition = 0;
    }
    this.channelService.setCurrentChannelValue(this.currentChannelGroup.channels[nextChannelPosition]);
  }

  public previousChannel(): void {
    let previousChannelPosition = 0;
    const channelPosition = this.getChannelIndex();
    if (channelPosition > 0) {
      previousChannelPosition = channelPosition - 1;
    } else if (channelPosition === 0) {
      previousChannelPosition = this.currentChannelGroup.channels.length - 1;
    }
    this.channelService.setCurrentChannelValue(this.currentChannelGroup.channels[previousChannelPosition]);
  }

  private getChannelIndex(): number {
    return this.currentChannelGroup.channels.map((channel) => channel.id).indexOf(this.currentChannel.id);
  }

  public changeVolume() {
    const volume = this.volumeSlider.value;
    this.audio.volume = volume / 100;
  }

  public changeCurrentTime() {
    const currentTime = this.durationSlider.value;
    this.audio.currentTime = currentTime;
  }

  public setFavorite() {
    this.isFavorite = true;
    this.favoritesService.setFavorite(this.currentChannel.id);
  }

  public removeFavorite(): void {
    this.isFavorite = false;
    this.favoritesService.removeFavorite(this.currentChannel.id);
  }

  protected getHHMMSS(secs) {
    const secNum = parseInt(secs, 10);
    const hours   = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
      .map(v => v < 10 ? '0' + v : v)
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  }
}
