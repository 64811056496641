import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLanguageSubject: BehaviorSubject<string>;
  private currentLanguageValue: Observable<string>;

  constructor() {
    let currentLanguage = '';
    if (!localStorage.getItem('hotelradioLanguage')) {
      currentLanguage = window.navigator.language;
      localStorage.setItem('hotelradioLanguage', currentLanguage);
    } else {
      currentLanguage = localStorage.getItem('hotelradioLanguage');
    }
    this.currentLanguageSubject = new BehaviorSubject<string>(currentLanguage);
    this.currentLanguageValue = this.currentLanguageSubject.asObservable();
  }

  public changeLanguage(newLanguage: string): void {
    localStorage.setItem('hotelradioLanguage', newLanguage);
    this.currentLanguageSubject.next(newLanguage);
  }

  public get currentLanguage(): Observable<string> {
    return this.currentLanguageValue;
  }
}
