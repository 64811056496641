import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Channel} from '../models/Channel';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {
  private currentChannelSubject: BehaviorSubject<Channel>;
  private currentChannel: Observable<Channel>;
  private currentChannelsSubject: BehaviorSubject<Channel[]>;
  private currentChannels: Observable<Channel[]>;
  private nowPlayingSubject: BehaviorSubject<any>;
  private nowPlayingValue: Observable<any>;
  private coverUrlSubject: BehaviorSubject<string>;
  private coverUrlValue: Observable<any>;
  private stream: ReadableStream;
  private audio = new Audio();
  private playPromiseDone = true;
  private fetchController = new AbortController();
  private readyToPlaySubject: BehaviorSubject<boolean>;
  private readyToPlayValue: Observable<boolean>;

  constructor(private http: HttpClient) {
    this.currentChannelsSubject = new BehaviorSubject<Channel[]>(null);
    this.currentChannels = this.currentChannelsSubject.asObservable();
    this.currentChannelSubject = new BehaviorSubject<Channel>(null);
    this.currentChannel = this.currentChannelSubject.asObservable();
    this.nowPlayingSubject = new BehaviorSubject<any>('');
    this.nowPlayingValue = this.nowPlayingSubject.asObservable();
    this.coverUrlSubject = new BehaviorSubject<string>('');
    this.coverUrlValue = this.coverUrlSubject.asObservable();
    this.readyToPlaySubject = new BehaviorSubject<boolean>(true);
    this.readyToPlayValue = this.readyToPlaySubject.asObservable();
  }

  public get currentChannelsValue(): Observable<Channel[]> {
    return this.currentChannels;
  }

  public getChannels(channelGroupId): Observable<any> {
    const url = environment.apiUrl + 'channels/group';
    const httpOptions = {
      observe: 'response' as 'response',
    };
    const httpParams = new HttpParams().set('id', channelGroupId);
    return this.http.post<any>(url, httpParams, httpOptions).pipe(map(res => {
      if (res.body.channels) {
        res.body.channels.forEach((channel) => {
          if (channel.channel_cover) {
            channel.channel_cover = channel.channel_cover.replace('http://', 'https://');
          }
          if (channel.coverart) {
            channel.coverart = channel.coverart.replace('http://', 'https://');
          }
        });
        this.currentChannelsSubject.next(res.body.channels);
        return res.body.channels;
      }
    }));
  }
  
  public setNowPlaying(title: string): void {
    this.nowPlayingSubject.next(title);
  }

  public get readyToPlay(): Observable<boolean> {
    return this.readyToPlayValue;
  }

  public setReadyToPlay(readyToPlay: boolean): void {
    this.readyToPlaySubject.next(readyToPlay);
  }

  public setCurrentChannelValue(channel: Channel): void {
    channel.stream_path = channel.stream_path.replace('http://', 'https://');
    this.currentChannelSubject.next(channel);
    this.fetchController.abort();
    this.fetchController = new AbortController();
    // this.initAudio(channel);
    // this.fetchAudio2(channel.stream_path + channel.stream_dir);
    // this.fetchAudio(channel.stream_path + channel.stream_dir);
  }

  /*private initAudio(channel: Channel): void {
    this.playPromiseDone = false;
    this.setReadyToPlay(false);
    this.audio.src = channel.stream_path + channel.stream_dir;
    this.audio.load();
    this.audio.play().then(() => {
      console.log('set play promise done true');
      this.playPromiseDone = true;
      this.setReadyToPlay(true);
    }).catch(e => console.log(e));
  }*/

  /*public pauseAudio(): void {
    this.audio.pause();
  }*/

  /*public playAudio(): void {
    this.audio.play().then(() => {
      console.log('Audio playing');
    }).catch((e) => {
      console.log('audio play error');
    });
  }*/

  public get currentChannelValue(): Observable<Channel> {
    return this.currentChannel;
  }

  public get nowPlaying(): Observable<any> {
    return this.nowPlayingValue;
  }

  public setCoverUrl(url: string): void {
    this.coverUrlSubject.next(url);
  }

  public get coverUrl(): Observable<string> {
    return this.coverUrlValue;
  }

  public fetchAudio2(url: string) {
    const fetchController2 = this.fetchController;
    const fetchSignal = fetchController2.signal;
    const nowPlaying = this.nowPlayingSubject;
    const Heads = new Headers({'Icy-Metadata': '1'});
    const stream = new ReadableStream({
      start(controller) {
        const songs = Array();
        const decoder = new TextDecoder('ascii');
        const startFetch = fetch(url, {signal: fetchSignal, headers: Heads, method: 'GET', mode: 'cors'});
        function pushStream(response) {
          let metaint = Number(response.headers.get('icy-metaint'));
          if (!metaint) {
            metaint = 1024;
          }
          const resstream = response.body;
          const reader = resstream.getReader();
          return reader.read().then(function process(result) {
            if (result.done) {
              return;
            }
            const chunk = result.value;
            for (let i = 0; i < chunk.length; i++) {
              songs.push(chunk[i]);
              if (songs.length > (metaint + 4080)) {
                const musicData = Uint8Array.from(songs.splice(0, metaint));
                const metalength = songs.shift() * 16;
                if (metalength > 0) {
                  const songtitle = decoder.decode(Uint8Array.from(songs.splice(0, metalength)));
                  nowPlaying.next(songtitle);
                  console.log(songtitle);
                }
                if (fetchSignal.aborted) {
                  fetchController2.abort();
                }
                controller.enqueue(musicData);
              }
            }
            return reader.read().then(process);
          });
        }
        startFetch.then(response => pushStream(response)).then(() => controller.close()).catch((e) => {
          console.log('Connection to stream cancelled');
          fetchController2.abort();
        });
      }
    });
  }

  /*fetchAudio(url: string) {
    if (this.stream) {
      this.stream.cancel().then(() => console.log('cancel'));
    }
    let fetchController = this.fetchController;
    if (this.fetchController) {
      console.log('call abort fetch');
      this.fetchController.abort();
    }
    const nowPlaying = this.nowPlayingSubject;
    let fetchSignal = null;
    const Heads = new Headers({'Icy-Metadata': '1'});
    this.stream = new ReadableStream({
      start(controller) {
        const songs = Array();
        fetchController = new AbortController();
        const signal = fetchController.signal;
        const decoder = new TextDecoder('utf-8');
        const startFetch = fetch(url, {signal, headers: Heads});

        function pushStream(response) {
          let metaint = Number(response.headers.get('icy-metaint'));
          if (!metaint) {
            metaint = 1024;
          }
          const stream = response.body;
          const reader = stream.getReader();
          return reader.read().then(function process(result) {
            if (result.done) {
              return;
            }
            const chunk = result.value;
            for (let i = 0; i < chunk.length; i++) {
              songs.push(chunk[i]);
              if (songs.length > (metaint + 4080)) {
                const musicData = Uint8Array.from(songs.splice(0, metaint));
                const metalength = songs.shift() * 16;
                if (metalength > 0) {
                  const songtitle = decoder.decode(Uint8Array.from(songs.splice(0, metalength)));
                  nowPlaying.next(songtitle);
                  console.log(songtitle);
                }
                if (fetchSignal == 1) {
                  fetchController.abort();
                }
                controller.enqueue(musicData);
              }
            }
            return reader.read().then(process);
          });
        }

        startFetch.then(response => pushStream(response)).then(() => controller.close()).catch((e) => {
          console.log('Connection to stream cancelled');
          fetchSignal = 0;
        });
      }
    });
  }*/

  /*loadBinarySource() {
    const url = 'http://ch-be04-ice.dmd2streaming.com/apresskihits.aacp';
    const req = new XMLHttpRequest();
    req.setRequestHeader('Icy-Metadata', '1');
    req.open('GET', url, true);
    req.overrideMimeType('text\/plain; charset=x-user-defined');
    req.send(null);
    if (req.status != 200) {
      return '';
    }
    return req.responseText;
  }*/
}
