import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../language/language.service';
import {ChannelService} from '../../channel/channel.service';
import {AuthService} from '../../auth/auth.service';
import {User} from '../../models/User';
import {PlayerService} from '../../player/player.service';
import {SearchService} from '../../search/search.service';
import {LanguageDE} from '../../models/languageDE';
import {LanguageEN} from '../../models/languageEN';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public site = environment.site;
  public logoPathBig = environment.assetsPath + 'logo_main_big_dark_background.svg';
  public logoPathSmall = environment.assetsPath + 'logo_main_small_dark_background.svg';
  public appleAppStoreLogo = environment.assetsPath + 'download_on_the_app_store.svg';
  public loggedInAs: User;
  public sideBarSmall = false;
  public isDarkMode: boolean;
  public isMonoMode: boolean;
  public languageFile = new LanguageDE();
  public googleLink = environment.googleLink;
  public appleLink = environment.appleLink;
  public websiteLink = environment.websiteLink;

  constructor(
    private languageService: LanguageService,
    private channelService: ChannelService,
    private authService: AuthService,
    private playerService: PlayerService,
    private searchService: SearchService
  ) {
  }

  ngOnInit() {
    this.authService.currentUserValue.subscribe((user) => this.loggedInAs = user);
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en') {
        this.languageFile = new LanguageEN();
      }
    });
    this.checkMonoMode();
    this.playerService.isDarkMode.subscribe((darkMode) => {
      if (darkMode) {
        this.isDarkMode = darkMode;
        this.logoPathBig = `${environment.assetsPath}logo_main_big_dark_background.svg`;
      } else {
        this.isDarkMode = darkMode;
        this.logoPathBig = `${environment.assetsPath}logo_main_big_light_background.svg`;
      }
    });
  }

  public logout(): void {
    this.authService.logout(this.loggedInAs.username).subscribe();
  }

  public showChannelGroups(): void {
    this.playerService.setSearchViewValue(false);
    this.playerService.setIsFavoriteViewValue(false);
    this.playerService.setIsPlayerValue(false);
    this.playerService.setIsHistoryViewValue(false);
  }

  public showFavoritesView(): void {
    this.playerService.setSearchViewValue(false);
    this.playerService.setIsPlayerValue(false);
    this.playerService.setIsHistoryViewValue(false);
    this.playerService.setIsFavoriteViewValue(true);
  }

  public showHistoryView(): void {
    this.playerService.setSearchViewValue(false);
    this.playerService.setIsPlayerValue(false);
    this.playerService.setIsFavoriteViewValue(false);
    this.playerService.setIsHistoryViewValue(true);
  }

  public showSearchView(): void {
    this.playerService.setIsPlayerValue(false);
    this.playerService.setIsFavoriteViewValue(false);
    this.playerService.setIsHistoryViewValue(false);
    this.playerService.setSearchViewValue(true);
  }

  public searchChannel(searchString: string): void {
    this.searchService.setSearchText(searchString);
  }

  private checkMonoMode(): void {
    this.playerService.isMonoMode.subscribe((isMonoMode) => {
      if (isMonoMode) {
        this.isMonoMode = isMonoMode;
      }
    });
  }

  public switchDarkMode(): void {
    this.isDarkMode = !this.isDarkMode;
    this.playerService.setIsDarkMode(this.isDarkMode);
  }

  public switchMonoMode(): void {
    this.isMonoMode = !this.isMonoMode;
    this.playerService.setIsMonoMode(this.isMonoMode);
  }
}
