import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';
import {LanguageDE} from "../models/languageDE";
import {LanguageService} from "../language/language.service";
import {LanguageEN} from "../models/languageEN";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  public site = environment.site;
  public logoPath = environment.assetsPath + 'logo_main_big_light_background.svg';
  public backgroundImage = `url("${environment.assetsPath + 'background_main.png'}")`;
  public languageFile = new LanguageDE();
  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en') {
        this.languageFile = new LanguageEN();
      }
    });
  }

  isAndroid() {
    if ( /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      console.log('android');
      return true;
    }
  }

  isIos() {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      console.log('ios');
      return true;
    }
  }
}
