import { Injectable } from '@angular/core';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {User} from '../models/User';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API} from '../models/API';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Md5} from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private api: API = new API();
  private loginUrl = environment.apiUrl + 'index/login';
  private logoutUrl = environment.apiUrl + 'index/logout';
  private httpOptions = this.api.httpOptions;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  redirectUrl: string;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Observable<User> {
    return this.currentUser;
  }

  public login2(username, password): Observable<any> {
    return this.logout(username).pipe(
      concatMap((val) => {
        return this.login(username, password);
      })
    );
  }

  public login(username, password): Observable<User> {
    // this.logout(username).subscribe();
    const body = new HttpParams().set('username', username).set('password', password);
    return this.http.post<any>(this.loginUrl, body, this.httpOptions).pipe(
      map((response) => {
        const user: User = new User();
        user.id = response.body.user.id;
        user.username = response.body.user.username;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.updateIP(user.id).subscribe();
        return user;
      })
    );
  }

  public logout(username: string): Observable<object> {
    const body = new HttpParams().set('username', username);
    localStorage.removeItem('currentUser');
    return this.http.post(this.logoutUrl, body, this.httpOptions).pipe(map(response => {
      if (response.ok) {
        this.router.navigate(['/auth']);
      }
      return response;
    }));
  }

  public resetPassword(username: string): Observable<any> {
    const requestUrl = environment.baseUrl + 'music/app/reset.php';
    const md5HashedUsername: string = Md5.hashStr(username).toString();
    // const body = new HttpParams().set('user', md5HashedUsername);
    // return this.http.post(requestUrl, body).pipe(map((response) => response));
    const body = new FormData();
    body.append('user', md5HashedUsername);
    return from(fetch(requestUrl, {
      body: body,
      method: 'POST',
      mode: "no-cors"
    }));
  }

  public updateIP(userId: number): Observable<any> {
    const httpOptions = {
      observe: 'response' as 'response',
    };
    const httpParams = new HttpParams().set('id', userId.toString());
    return this.http.post<any>('https://your.dmd2.com/api/user/updateip', httpParams, httpOptions).pipe(
      map(res => res)
    );
  }

  public fetchUserInfo(): Observable<any> {
    const userInfoUrl = environment.baseUrl + 'pl/app/';
    const httpParams = new HttpParams().set('action', 'fetch-user').set('id', this.currentUserSubject.value.id.toString());
    return this.http.get(userInfoUrl,  {params: httpParams}).pipe(map(response => {
      return response;
    }));
  }
}
