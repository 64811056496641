import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('intecept');
    
    if (req.url.includes('spotify')) {
      return next.handle(req);
    }
    
    if (req.url.includes('stream/nowplaying')) {
      return next.handle(req);
    }
    
    if (req.url.includes('reset.php')) {
      return next.handle(req);
    }
    
    req = req.clone({
      withCredentials: true
    });
    return next.handle(req); /*.pipe(tap(ev => {
      console.log(ev);
    }));*/
  }
}
