import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {User} from '../models/User';
import {AuthService} from '../auth/auth.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {ChannelGroup} from '../models/ChannelGroup';
import {ChannelService} from './channel.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelGroupService {
  private header = new HttpHeaders({
    'content-type': 'application/x-www-form-urlencoded'
  });
  private currentUser: User = new User();
  private channelGroupUrl = environment.apiUrl + 'channelgroups/user';
  private channelGroupsSubject: BehaviorSubject<ChannelGroup[]>;
  private channelGroups: Observable<ChannelGroup[]>;
  private currentChannelGroupSubject: BehaviorSubject<ChannelGroup>;
  private currentChannelGroup: Observable<ChannelGroup>;
  private showSafariMessageSubject: BehaviorSubject<boolean>;
  private showSafariMessage: Observable<boolean>;

  constructor( private authService: AuthService, private http: HttpClient, private router: Router, private channelService: ChannelService) {
    this.showSafariMessageSubject = new BehaviorSubject<boolean>(false);
    this.showSafariMessage = this.showSafariMessageSubject.asObservable();
    this.authService.currentUserValue.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        const httpOptions = {
          headers: this.header,
          observe: 'response' as 'response',
          withCredentials: true
        };
        const httpParams = new HttpParams().set('id', user.id.toString());
        this.http.post<any>(this.channelGroupUrl, httpParams, httpOptions).subscribe(response => {
          const body = response.body;
          if (response.status === 401) {
            this.authService.updateIP(this.currentUser.id).subscribe();
          }
          if (body.status === 'Welcome to the hotelradio.fm RESTful web service API!') {
            if (this.isSafari()) {
              this.showSafariMessageSubject.next(true);
              return;
            }
            this.router.navigate(['/login']);
          } else if (body.channel_groups) {
            body.channel_groups.forEach((channelGroup) => {
              channelGroup.group_cover = channelGroup.group_cover.replace('http://', 'https://');
              this.channelService.getChannels(channelGroup.id).subscribe((channels) => {
                channelGroup.channels = channels;
              });
            });
            this.channelGroupsSubject.next(body.channel_groups);
          }
          return response.body;
        });
      } else {
        this.authService.logout(this.currentUser.username).subscribe();
      }
    });
    this.channelGroupsSubject = new BehaviorSubject<ChannelGroup[]>(null);
    this.channelGroups = this.channelGroupsSubject.asObservable();
    this.currentChannelGroupSubject = new BehaviorSubject<ChannelGroup>(null);
    this.currentChannelGroup = this.currentChannelGroupSubject.asObservable();
  }

  public get channelGroupsValue(): Observable<ChannelGroup[]> {
    return this.channelGroups;
  }

  public setCurrentChannelGroup(channelGroup: ChannelGroup): void {
    this.currentChannelGroupSubject.next(channelGroup);
  }

  public get currentChannelGroupValue() {
    return this.currentChannelGroup;
  }

  public get showSafariMessageValue(): Observable<boolean> {
    return this.showSafariMessage;
  }

  private isSafari(): boolean {
    if (navigator.userAgent.toLowerCase().indexOf('chrome/') > -1) {
      return false;
    }
    return navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
  }
}
