import { Component, OnInit } from '@angular/core';
import {ChannelGroupService} from '../../channel/channel-group.service';
import {FavoritesService} from '../favorites.service';
import {Channel} from '../../models/Channel';
import {ChannelGroup} from '../../models/ChannelGroup';
import {ChannelService} from '../../channel/channel.service';
import {PlayerService} from '../../player/player.service';
import {HistoryService} from '../../history/history.service';
import {LanguageDE} from '../../models/languageDE';
import {LanguageService} from '../../language/language.service';
import {LanguageEN} from '../../models/languageEN';
import {combineLatest} from 'rxjs';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  public channels: Channel[] = [];
  public channelGroup: ChannelGroup;
  public languageFile = new LanguageDE();

  constructor(
    private languageService: LanguageService,
    private channelGroupService: ChannelGroupService,
    private favoritesService: FavoritesService,
    private channelService: ChannelService,
    private playerService: PlayerService,
    private historyService: HistoryService
  ) { }

  ngOnInit() {
    const channelGroupsObservable = this.channelGroupService.channelGroupsValue;
    const favoritesObservable = this.favoritesService.favoritesValue;
    this.channelGroup = new ChannelGroup();
    this.channelGroup.group_name = 'Favoriten';
    this.channelGroup.id = 0;
    this.languageService.currentLanguage.subscribe((language) => {
      if (language === 'de-DE' || language === 'de') {
        this.languageFile = new LanguageDE();
      } else if (language === 'en-US' || language === 'en') {
        this.languageFile = new LanguageEN();
      }
    });
    combineLatest(channelGroupsObservable, favoritesObservable).subscribe(([channelGroups, favorites]) => {
      const favoriteChannels = [];
      const idArray = [];
      channelGroups
        .filter((channelGroup) => {
          return channelGroup.channels;
        })
        .forEach((channelGroup) => {
          channelGroup.channels.filter((channel) => {
            return favorites.includes(channel.id);
          }).forEach((channel) => {
            if (!idArray.includes(channel.id)) {
              if (!channel.coverart && channelGroup.group_cover) {
                channel.coverart = channelGroup.group_cover;
              }
              favoriteChannels.push(channel);
              idArray.push(channel.id);
            }
          });
      });
      this.channelGroup.channels = favoriteChannels;
      this.channels = favoriteChannels;
    });
  }

  public selectChannel(channel: Channel, channelGroup: ChannelGroup) {
    this.channelService.setCurrentChannelValue(channel);
    this.channelGroupService.setCurrentChannelGroup(channelGroup);
    this.playerService.setIsFavoriteViewValue(false);
    this.playerService.setSearchViewValue(false);
    this.playerService.setIsHistoryViewValue(false);
    this.playerService.setIsPlayerValue(true);
    this.historyService.setHistoryValue(channel.id);
  }

}
